import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  loading: any;
  card_token: any = new BehaviorSubject('');

  constructor(
    public loadingCtrl: LoadingController,
    public toastController: ToastController,
    public alertCtrl: AlertController,
    private router: Router,
    private alertController: AlertController,
  ) {

  }

  async showLoader(msg) {
    this.loading = await this.loadingCtrl.create({ message: msg, cssClass: 'custom-load' });
    this.loading.present();
  }

  async dismissLoading() {
    await this.loading.dismiss();
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'bottom'
    });
    toast.present();
  }

  async presentAlert(msg, heading = 'Alert'): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let alert = await this.alertCtrl.create({
        header: heading,
        message: msg,
        buttons: [{
          text: 'OK',
          handler: () => {
            resolve(true);
          }
        }],
        backdropDismiss: false
      });
      await alert.present();
    })
  }



  async goToUpgradeMembership(msg: string = '', url: '') {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: 'Alert',
        // subHeader: 'Are you sure?',
        message: msg,
        mode: 'ios',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              console.log('cancelled');
              reject;
            },
          },
          {
            text: 'OK',
            role: 'confirm',
            handler: () => {
              this.router.navigate(['/upgrade-account'], { queryParams: { membership_web_url: url } });
              resolve(true);
            },
          },
        ],
      });
      await alert.present();
    })
  }

}
