import { ChangeDetectorRef, Component } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { ApiService } from './services/api.service';
import { AlertServiceService } from './services/alert-service.service';
import { StorageService } from './services/storage.service';
import { Router } from '@angular/router';
import { NavigationService } from './services/navigation.service';
import { Events } from './services/events';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import OneSignal from 'onesignal-cordova-plugin';
import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { CommonService } from './services/common.service';
import { SplashScreen } from '@capacitor/splash-screen';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent {
  deviceData: any;
  uuid: string;
  model: any;
  device_platform: any;
  device_version: any;
  loginUser: any = [];
  menuPages: any = [];
  user_role: any;
  deviceToken: any;
  info: any;
  timezone: string;

  constructor(
    private platform: Platform,
    public storageService: StorageService,
    public apiService: ApiService,
    public alertServiceService: AlertServiceService,
    private menu: MenuController,
    private naviGation: NavigationService,
    public router: Router,
    private events: Events,
    private cd: ChangeDetectorRef,
    private commomSerivce: CommonService
  ) {
    defineCustomElements(window);

    // ---- for internet connectivity .
    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status);
      if (!status.connected) {
        this.commomSerivce.presentAlert('No internet connection!');
      }
    });

    let devicePlatform = Capacitor.getPlatform();
    this.storageService.get('caddy_user').then((user) => {
      if (user != null) {
        this.loginUser = JSON.parse(user);
      }
    });

    this.events.subscribe('homeRefresh', (data) => {
      if (data != null) {
        this.loginUser = data;
        this.user_role = this.loginUser.user_role;
        this.roleWiseMenu();
      }
    });

    this.initializeApp();
    this.platform.resume.subscribe(async () => {
      console.log('resume event');
      // this.events.publish('readNotification', true);
    });

  }


  ionViewWillEnter() {
    this.storageService.get('caddy_user').then((user) => {
      if (user != null) {
        this.loginUser = JSON.parse(user);
        this.user_role = this.loginUser.user_role;
        this.roleWiseMenu();
      }
    });
    this.getStripeSetting();

  }


  initializeApp() {
    this.platform.ready().then(async () => {
      if (this.platform.is('capacitor')) {  
      this.OneSignalInit();
      }
      await this.storageService.get('caddy_user').then((user) => {
        if (user != null) {
          this.loginUser = JSON.parse(user);
          this.user_role = this.loginUser.user_role;
          // this.events.publish('readNotification', true);
          this.roleWiseMenu();
        }
      });
      await SplashScreen.show({
        showDuration: 5000,
        autoHide: true,
      });
      if (this.platform.is('android')) {
        this.registerNotifications();
      }
      this.redirectUser();
      this.info = await Device.getInfo();
    });
  }


  roleWiseMenu() {
    if (this.user_role == 'golfer') {
      this.menuPages = [
        {
          title: 'My Pending Booking Requests',
          url: '/my-pending-request'
        },
        {
          title: 'F.A.Q.',
          url: '/faq'
        },
        {
          title: 'My Profile',
          url: '/tabs/golfer-profile',
        },
        {
          title: 'My Wallet',
          url: '/my-wallet',
        },
        {
          title: 'About Us',
          url: '/about-us',
        },
        {
          title: 'Contact Us',
          url: '/contact-us',
        }
      ];
    }
    if (this.user_role == 'caddy') {
      // -------- CAddy --

      this.menuPages = [
        {
          title: 'My Pending Booking Requests',
          url: '/my-pending-request'
        },
        {
          title: 'Manage Courses',
          url: "/manage-courses"
        },
        {
          title: 'F.A.Q.',
          url: '/faq'
        },
        {
          title: 'About Us',
          url: 'about-us'
        },
        {
          title: 'Contact Us',
          url: 'contact-us',
        },
        {
          title: 'Settings',
          url: '/setting'
        }
      ]
    }

    if (this.user_role == 'course-pro') {
      // ---------- courses -------

      this.menuPages = [
        {
          title: 'F.A.Q.',
          url: '/faq'
        },
        {
          title: 'Payments',
          url: '/my-wallet'
        },
        {
          title: 'About Us',
          url: '/about-us'
        },
        {
          title: 'Contact Us',
          url: '/contact-us'
        },
        {
          title: 'Settings',
          url: '/setting'
        }
      ];

      if (this.loginUser.coursePro == 'yes' && this.loginUser?.membership_data?.is_membership) {
        this.menuPages.unshift({ title: 'My Pending Booking Requests', url: '/my-pending-request' });
      }
      if(this.loginUser?.course_pro != 'yes' || !this.loginUser?.membership_data?.is_membership) {
        if (this.menuPages.findIndex((res) => res.title == "My Pending Booking Requests")) {
          this.menuPages.splice(0, 1);
        }
      }

    }
    this.cd.detectChanges();
  }

  async redirectUser() {
    this.storageService.get('caddy_user').then((user) => {
      if (user != null) {
        this.loginUser = JSON.parse(user);
        let user_data = JSON.parse(user);

        if (user_data.user_role == 'course-pro') {
          this.naviGation.goToRoot('/tabs/home-course');
        } if (user_data.user_role != 'course-pro') {
          this.naviGation.goToRoot('/tabs/home');
        }
      } else {
        this.naviGation.goToRoot('/login');
      }
    });
  }

  logout() {
    const alertHeader = 'Logout';
    const alertMessage = 'Are you sure?';
    this.alertServiceService
      .presentAlertWithHeader(alertHeader, alertMessage)
      .then(async (userResponse) => {
        if (userResponse) {
          // ----------- Remove device token after logout --------------
          if (this.platform.is('capacitor')) {
            this.logOutdevice(this.loginUser.token);
          }
          // ------------- End removing device token ----------------
          this.storageService.clearAll();
          this.menu.enable(false);
          this.naviGation.setRoot('/login');
        }
      });
  }


  async logOutdevice(token) {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    await this.getData();
    this.info = await Device.getInfo();
    let body: any = {};
    await Device.getId().then(val => {
      body.token = token;
      body.device_token = this.deviceToken;
      body.device_uuid = val.uuid;
      body.device_platform = this.info?.platform;
      body.timezone = this.timezone;
      body.device_version = this.info.osVersion;
      body.device_model = this.info?.model
      body.action = 'logout';
      this.apiService.sendData('storeDeviceDetails', body).subscribe((resp) => {
      }, (err) => {
      });
    });
  }


  getData(): Promise<any> {
    return new Promise(resolve => {
      OneSignal.getDeviceState((res: any) => {
        let temp: any = res;
        this.deviceToken = temp.userId;
        resolve(true);
      });
    });
  }

  closeMenu() {
    this.menu.close();
    this.redirectUser();
  }


  OneSignalInit() {
    var self = this;
    OneSignal.setAppId("b6d5a3ea-6d6e-449a-9b28-d575e272c193");
    OneSignal.setNotificationOpenedHandler(function (jsonData) {
      if (jsonData) {
        self.router.navigate(['/tabs/notification']);

      }
    });

    OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
      console.log("User accepted notifications: " + accepted);
    });

    OneSignal.setNotificationWillShowInForegroundHandler(function (data) {
      console.log('Notification received');
      // self.events.publish('readNotification', true);
    });

    setTimeout(() => {
      OneSignal.getDeviceState(res => {
        let temp: any = res;
        this.deviceToken = temp.userId;
      });
    }, 2000);
  }

  registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }
  }

  getStripeSetting() {
    this.apiService.getData('get_stripe_setting').subscribe((res) => {
      this.apiService.stripe_setting.next(res);
    }, (err) => {
      console.log("get_stripe_setting err", err);
    });
  }
}

