import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./beforeLoginPages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./beforeLoginPages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./beforeLoginPages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'reset-password/:user_id',
    loadChildren: () => import('./beforeLoginPages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'spacial-code/:user_id',
    loadChildren: () => import('./beforeLoginPages/spacial-code/spacial-code.module').then( m => m.SpacialCodePageModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./commonpages/about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./commonpages/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./commonpages/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./commonpages/help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./commonpages/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./commonpages/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'chooserole',
    loadChildren: () => import('./beforeLoginPages/chooserole/chooserole.module').then( m => m.ChooserolePageModule)
  },
  {
    path: 'add-references',
    loadChildren: () => import('./beforeLoginPages/add-references/add-references.module').then( m => m.AddReferencesPageModule)
  },
  {
    path: 'add-payment',
    loadChildren: () => import('./beforeLoginPages/add-payment/add-payment.module').then( m => m.AddPaymentPageModule)
  },
  {
    path: 'referral-agreement',
    loadChildren: () => import('./beforeLoginPages/referral-agreement/referral-agreement.module').then( m => m.ReferralAgreementPageModule)
  },
  {
    path: 'tos',
    loadChildren: () => import('./beforeLoginPages/tos/tos.module').then( m => m.TOSPageModule)
  },
  {
    path: 'terms-of-service',
    loadChildren: () => import('./beforeLoginPages/terms-of-service/terms-of-service.module').then( m => m.TermsOfServicePageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./beforeLoginPages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'selected-notification',
    loadChildren: () => import('./commonpages/selected-notification/selected-notification.module').then( m => m.SelectedNotificationPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./commonpages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'my-courses',
    loadChildren: () => import('./commonpages/my-courses/my-courses.module').then( m => m.MyCoursesPageModule)
  },
  {
    path: 'my-golfers',
    loadChildren: () => import('./commonpages/my-golfers/my-golfers.module').then( m => m.MyGolfersPageModule)
  },
  {
    path: 'signup-course',
    loadChildren: () => import('./beforeLoginPages/signup-course/signup-course.module').then( m => m.SignupCoursePageModule)
  },
  {
    path: 'signup-golfer',
    loadChildren: () => import('./beforeLoginPages/signup-golfer/signup-golfer.module').then( m => m.SignupGolferPageModule)
  },
  {
    path: "my-caddies-courses",
    loadChildren: () => import('./pages/my-caddies-courses/my-caddies-courses.module').then( m  =>  m.MyCaddiesCoursesPageModule)
  },
  {
    path : "course-caddies",
    loadChildren: () => import('./pages/course-caddies/course-caddies.module').then( m  =>  m.CourseCaddiesPageModule)
  },
  {
    path: "course-single",
    loadChildren: () => import('./pages/course-single/course-single.module').then( m  =>  m.CourseSinglePageModule)
  },
  {
    path: 'selected-course-fees',
    loadChildren: () => import('./pages/selected-course-fees/selected-course-fees.module').then( m => m.SelectedCourseFeesPageModule)
  },
  {
    path: 'course-caddy-setting',
    loadChildren: () => import('./pages/course-caddy-setting/course-caddy-setting.module').then( m => m.CourseCaddySettingPageModule)
  },
  {
    path: 'caddy-application',
    loadChildren: () => import('./pages/caddy-application/caddy-application.module').then( m => m.CaddyApplicationPageModule)
  },
  {
    path: 'selected-caddy',
    loadChildren: () => import('./pages/selected-caddy/selected-caddy.module').then( m => m.SelectedCaddyPageModule)
  },
  {
    path: 'course-dashboard',
    loadChildren: () => import('./pages/course-dashboard/course-dashboard.module').then( m => m.CourseDashboardPageModule)
  },
  {
    path: 'home-course',
    loadChildren: () => import('./pages/home-course/home-course.module').then( m => m.HomeCoursePageModule)
  },
  {
    path: 'course-setting',
    loadChildren: () => import('./pages/course-setting/course-setting.module').then( m => m.CourseSettingPageModule)
  },
  {
    path: 'caddy-policy',
    loadChildren: () => import('./pages/caddy-policy/caddy-policy.module').then( m => m.CaddyPolicyPageModule)
  },
  {
    path: 'course-profile',
    loadChildren: () => import('./pages/course-profile/course-profile.module').then( m => m.CourseProfilePageModule)
  },
  {
    path: 'selected-course',
    loadChildren: () => import('./pages/selected-course/selected-course.module').then( m => m.SelectedCoursePageModule)
  },
  {
    path: 'golfer-profile',
    loadChildren: () => import('./pages/golfer-profile/golfer-profile.module').then( m => m.GolferProfilePageModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('./pages/setting/setting.module').then( m => m.SettingPageModule)
  },
  {
    path: 'caddy-requests',
    loadChildren: () => import('./pages/caddy-requests/caddy-requests.module').then( m => m.CaddyRequestsPageModule)
  },
  {
    path: 'edit-date-time',
    loadChildren: () => import('./pages/edit-date-time/edit-date-time.module').then( m => m.EditDateTimePageModule)
  },
  {
    path: 'available-caddies',
    loadChildren: () => import('./pages/available-caddies/available-caddies.module').then( m => m.AvailableCaddiesPageModule)
  },
  {
    path: 'course-events',
    loadChildren: () => import('./pages/course-events/course-events.module').then( m => m.CourseEventsPageModule)
  },
  {
    path: 'new-events',
    loadChildren: () => import('./pages/new-events/new-events.module').then( m => m.NewEventsPageModule)
  },
  {
    path: 'preview-events',
    loadChildren: () => import('./pages/preview-events/preview-events.module').then( m => m.PreviewEventsPageModule)
  },
  {
    path: 'selected-events',
    loadChildren: () => import('./pages/selected-events/selected-events.module').then( m => m.SelectedEventsPageModule)
  },
  {
    path: 'edit-event',
    loadChildren: () => import('./pages/edit-event/edit-event.module').then( m => m.EditEventPageModule)
  },
  {
    path: 'invite-caddies',
    loadChildren: () => import('./pages/invite-caddies/invite-caddies.module').then( m => m.InviteCaddiesPageModule)
  },
  {
    path: 'caddy-requests-two',
    loadChildren: () => import('./pages/caddy-requests-two/caddy-requests-two.module').then( m => m.CaddyRequestsTwoPageModule)
  },
  {
    path: 'selected-event-requests',
    loadChildren: () => import('./pages/selected-event-requests/selected-event-requests.module').then( m => m.SelectedEventRequestsPageModule)
  },
  {
    path: 'book-a-caddy',
    loadChildren: () => import('./pages/book-a-caddy/book-a-caddy.module').then( m => m.BookACaddyPageModule)
  },
  {
    path: 'preview-event-booking',
    loadChildren: () => import('./pages/preview-event-booking/preview-event-booking.module').then( m => m.PreviewEventBookingPageModule)
  },
  {
    path: 'select-golfer',
    loadChildren: () => import('./pages/select-golfer/select-golfer.module').then( m => m.SelectGolferPageModule)
  },
  {
    path: 'selected-golfer',
    loadChildren: () => import('./pages/selected-golfer/selected-golfer.module').then( m => m.SelectedGolferPageModule)
  },
  {
    path: 'golfer-and-game',
    loadChildren: () => import('./pages/golfer-and-game/golfer-and-game.module').then( m => m.GolferAndGamePageModule)
  },
  {
    path: 'review-booking',
    loadChildren: () => import('./pages/review-booking/review-booking.module').then( m => m.ReviewBookingPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./commonpages/faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'upcoming-booking',
    loadChildren: () => import('./pages/upcoming-booking/upcoming-booking.module').then( m => m.UpcomingBookingPageModule)
  },
  {
    path: 'cancel-booking',
    loadChildren: () => import('./pages/cancel-booking/cancel-booking.module').then( m => m.CancelBookingPageModule)
  },
  {
    path: 'edit-booking-attention',
    loadChildren: () => import('./pages/edit-booking-attention/edit-booking-attention.module').then( m => m.EditBookingAttentionPageModule)
  },
  {
    path: 'upcoming-bookings',
    loadChildren: () => import('./pages/upcoming-booking/upcoming-booking.module').then( m => m.UpcomingBookingPageModule)
  },
  {
    path: 'completed-bookings',
    loadChildren: () => import('./pages/completed-bookings/completed-bookings.module').then( m => m.CompletedBookingsPageModule)
  },
  {
    path: 'add-hour-of-operation',
    loadChildren: () => import('./pages/add-hour-of-operation/add-hour-of-operation.module').then( m => m.AddHourOfOperationPageModule)
  },
  {
    path: 'caddies',
    loadChildren: () => import('./pages/caddies/caddies.module').then( m => m.CaddiesPageModule)
  },
  {
    path: 'delete-account',
    loadChildren: () => import('./commonpages/delete-account/delete-account.module').then( m => m.DeleteAccountPageModule)
  },
  {
    path: 'caddy-event-notification',
    loadChildren: () => import('./pages/caddy-event-notification/caddy-event-notification.module').then( m => m.CaddyEventNotificationPageModule)
  },
  {
    path: 'review-caddy',
    loadChildren: () => import('./pages/review-caddy/review-caddy.module').then( m => m.ReviewCaddyPageModule)
  },
  {
    path: 'golfer-caddy-requests',
    loadChildren: () => import('./pages/golfer-caddy-requests/golfer-caddy-requests.module').then( m => m.GolferCaddyRequestsPageModule)
  },
  {
    path: 'update-availability',
    loadChildren: () => import('./pages/update-availability/update-availability.module').then( m => m.UpdateAvailabilityPageModule)
  },
  {
    path: 'my-availability',
    loadChildren: () => import('./pages/my-availability/my-availability.module').then( m => m.MyAvailabilityPageModule)
  },
  {
    path: 'start-booking',
    loadChildren: () => import('./pages/start-booking/start-booking.module').then( m => m.StartBookingPageModule)
  },
  {
    path: 'book-courses-caddy',
    loadChildren: () => import('./pages/book-courses-caddy/book-courses-caddy.module').then( m => m.BookCoursesCaddyPageModule)
  },
  {
    path: 'golfer-my-reviews',
    loadChildren: () => import('./pages/golfer-my-reviews/golfer-my-reviews.module').then( m => m.GolferMyReviewsPageModule)
  },
  {
    path: 'my-fees',
    loadChildren: () => import('./pages/my-fees/my-fees.module').then( m => m.MyFeesPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'my-reviews',
    loadChildren: () => import('./pages/my-reviews/my-reviews.module').then( m => m.MyReviewsPageModule)
  },
  {
    path: 'my-application',
    loadChildren: () => import('./pages/my-application/my-application.module').then( m => m.MyApplicationPageModule)
  },
  {
    path: 'edit-resume',
    loadChildren: () => import('./pages/edit-resume/edit-resume.module').then( m => m.EditResumePageModule)
  },
  {
    path: 'references',
    loadChildren: () => import('./pages/references/references.module').then( m => m.ReferencesPageModule)
  },
  {
    path: 'my-wallet',
    loadChildren: () => import('./commonpages/my-wallet/my-wallet.module').then( m => m.MyWalletPageModule)
  },
  {
    path: 'add-golfer-request-time',
    loadChildren: () => import('./pages/add-golfer-request-time/add-golfer-request-time.module').then( m => m.AddGolferRequestTimePageModule)
  },
  {
    path: 'golfer-request-review',
    loadChildren: () => import('./pages/golfer-request-review/golfer-request-review.module').then( m => m.GolferRequestReviewPageModule)
  },
  {
    path: 'w9',
    loadChildren: () => import('./beforeLoginPages/w9/w9.module').then( m => m.W9PageModule)
  },
  {
    path: 'add-payoutcard',
    loadChildren: () => import('./commonpages/add-payoutcard/add-payoutcard.module').then( m => m.AddPayoutcardPageModule)
  },
  {
    path: 'transaction-history',
    loadChildren: () => import('./pages/transaction-history/transaction-history.module').then( m => m.TransactionHistoryPageModule)
  },
  {
    path: 'my-pending-request',
    loadChildren: () => import('./pages/my-pending-request/my-pending-request.module').then( m => m.MyPendingRequestPageModule)
  },
  {
    path: 'discount-codes',
    loadChildren: () => import('./pages/discount-codes/discount-codes.module').then( m => m.DiscountCodesPageModule)
  },  {
    path: 'send-invite-request',
    loadChildren: () => import('./pages/send-invite-request/send-invite-request.module').then( m => m.SendInviteRequestPageModule)
  },
  {
    path: 'booking-setting',
    loadChildren: () => import('./pages/booking-setting/booking-setting.module').then( m => m.BookingSettingPageModule)
  },
  {
    path: 'upgrade-account',
    loadChildren: () => import('./pages/upgrade-account/upgrade-account.module').then( m => m.UpgradeAccountPageModule)
  },
  {
    path: 'manage-courses',
    loadChildren: () => import('./pages/manage-courses/manage-courses.module').then( m => m.ManageCoursesPageModule)
  },
  {
    path: 'event-bookings',
    loadChildren: () => import('./pages/event-bookings/event-bookings.module').then( m => m.EventBookingsPageModule)
  },




];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
