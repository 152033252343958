import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { delay, map, mergeScan, retry, retryWhen, scan, tap, } from 'rxjs/operators';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { StorageService } from '../services/storage.service';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from './common.service';
import { Network } from '@capacitor/network';


const Url = "https://mycaddy.betaplanets.com/";
const fullUrl = Url + 'wp-json/mobileapi/v1/';
const apiV2 = Url + 'wp-json/wp/v2/';
const cardTokenUrl = 'https://api.stripe.com/v1/tokens';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  loading: any;
  stripe_setting: any = new BehaviorSubject([]);
  stripe_keys: any;
  constructor(
    public navCtrl: NavController,
    private http: HttpClient,
    public loadingCtrl: LoadingController,
    public storage: StorageService,
    private toastCtrl: ToastController,
    private alertController: AlertController,
    private commonService: CommonService
  ) {
    this.stripe_setting.subscribe((res) => {
      this.stripe_keys = res;
    });
  }


  async dismissLoading() {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }

  async showLoader(msg: string = '') {
    if (msg === '') {
      msg = 'Please wait...';
    }
    this.loading = await this.loadingCtrl.create({ message: msg });
    await this.loading.present();
  }


  async showTransparentLoader() {
    this.loading = await this.loadingCtrl.create({
      message:
        '<ion-img src="/assets/icon/loader.gif" alt="loading..."></ion-img>',
      cssClass: "scale-down-center",
      translucent: true,
      showBackdrop: true,
      spinner: null,
      // duration: 4000,
    });
    this.loading.present();
  }

  async successToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      color: 'success',
      duration: 3000,
      position: 'bottom'
    });
    toast.present();
  }

  async errorToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      color: 'danger',
      duration: 3000,
      position: 'bottom'
    });
    toast.present();
  }

  setToken(token) {
    window.localStorage.setItem("token", token);
  }

  getToken() {
    return JSON.parse(window.localStorage.getItem("token"));
  }

  presentAlert(alertMessage: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'artstock_alert_2',
        message: alertMessage,
        header: 'Alert',
        mode: 'ios',
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
            handler: () => resolve(false)
          }
        ],
        backdropDismiss : false
      });

      await alert.present();
    });
  }

  sendData(endPoint, data) {
    this.logCurrentNetworkStatus();
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // timezone = "America/Los_Angeles"; 
    // ~~~~ new added section.
    let httpOptions = {
      headers: new HttpHeaders({
        'Timezone-Offset': timezone
      }),
    }
    
  
    return this.http.post(fullUrl + endPoint, data,httpOptions).pipe(map((result) => result)
      // , retryWhen(error =>
      //   error.pipe(
      //     scan((acc, error) => {
      //       if (acc > 2) {
      //         throw error;
      //       }
      //       console.log('attempt count:>>', acc);
      //       return acc + 1;
      //     }, 1),
      //     delay(2000),
      //     tap(() => console.log('Retrying...'))
      //   ))
    );
  }

  doLogin(endPoint, data) {
    return this.http.post(Url + 'wp-json/jwt-auth/v1/' + endPoint, data).pipe(
      map(data => {
        return data;
      })
    )
  }

  getData(endPoint) {
    this.logCurrentNetworkStatus();
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // timezone = "America/Los_Angeles";
    console.log('timezone:>>',timezone);
    let httpOptions = {
      headers: new HttpHeaders({
        'Timezone-Offset': timezone
      }),
    };

    return this.http.get(fullUrl + endPoint,httpOptions).pipe(map((data) => data), retryWhen(error =>
      error.pipe(
        scan((acc, error) => {
          if (acc > 2) throw error;
          console.log("attempt " + acc);
          return acc + 1;
        }, 1),
        delay(2000),
        tap(() => console.log("Retrying ..."))
      )
    ));
  }

  generateCardToken(card) {
    let body = `card[number]=${card.card_number}&card[exp_month]=${card.card_expire.substring(0, 2)}&card[exp_year]=${card.card_expire.substring(3, 7)}&card[cvc]=${card.card_cvv}&card[name]=${card.card_user_name}&card[currency]=${'USD'}&card[address_line1]=${card.billing_address}&card[address_city]=${card.city}&card[address_state]=${card.state}&card[address_zip]=${card.zip_code}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.stripe_keys.publisher_key,
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.post(cardTokenUrl, body, { headers: headers });
  }

  // ------ check network status.

  async logCurrentNetworkStatus() {
    const status = await Network.getStatus();
    if (!status.connected) {
      this.commonService.presentAlert('No internet connection!');
    }
  };


  async alertSlotMessag(freeSlots) {
    // Remove objects with duration of 2.5 and remove duplicates
    let uniqueFreeSlots = await this.removeDuplicatesAndTwoFive(freeSlots);
    let message = "Caddy is available between ";
    uniqueFreeSlots.forEach((slot, index) => {
      message += `${slot.slot_start} to ${slot.slot_end}`;
      if (index !== uniqueFreeSlots.length - 1) {
        message += ", ";
      }
    });
    this.commonService.presentAlert(message);
  }


  // Function to remove objects with duration of 2.5 and remove duplicates
  removeDuplicatesAndTwoFive(array): Promise<any> {
    return new Promise(resole => {
      // Use a Map to store unique objects based on all keys except "duration"
      const uniqueMap = new Map();
      array.forEach(entry => {
        // Create a unique key for each object excluding "duration"
        let key = Object.entries(entry)
          .filter(([key]) => key !== 'duration')
          .map(([_, value]) => value)
          .join('|');
        // Store the object in the Map using the unique key
        if (entry.duration !== 2.5 || !uniqueMap.has(key)) {
          uniqueMap.set(key, entry);
        }
      });
      // Return the values from the Map (unique objects)
      resole(Array.from(uniqueMap.values()));
    });

  }









}

